export interface RegistrationApi {
	AmountPersons: number | null
	AmountVegetarians: number | null
	WithPublicTransport: boolean
	WithCar: boolean
	WithRideshare: boolean
}

export function defaultRegistrationApi(): RegistrationApi {
	return {
		AmountPersons: null,
		AmountVegetarians: null,
		WithPublicTransport: false,
		WithCar: false,
		WithRideshare: false,
	}
}
