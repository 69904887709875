export default function Timeline() {
	return (
		<div className="component component-timeline">
			<div className="container">
				<h1>Ablauf</h1>
				<hr />
				<div className="d-flex justify-content-center">
					<div
						style={{
							maxWidth: '450px',
						}}
					>
						<img src="img/timeline/timeline.jpg" alt="timeline" className="img-fluid" />
					</div>
				</div>
			</div>
		</div>
	)
}
