import { useRef } from 'react'
import { useEffect } from 'react'

export default function Navigation() {
	const navigation = useRef<HTMLDivElement | null>(null)

	const handleScroll = (_event: any) => {
		if (window.scrollY < 410 && navigation.current !== null) {
			navigation.current.classList.remove('navigation-second')
			const _temp = navigation.current.offsetWidth
			navigation.current.classList.add('navigation-first')
		}
		if (window.scrollY >= 410 && navigation.current !== null) {
			navigation.current.classList.remove('navigation-first')
			const _temp = navigation.current.offsetWidth
			navigation.current.classList.add('navigation-second')
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	return (
		<nav ref={navigation} className="navbar navbar-expand-lg navbar-light navigation-first">
			<div className="container-fluid">
				<button
					className="navbar-toggler"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarNavDropdown"
					aria-controls="navbarNavDropdown"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon"></span>
				</button>
				<div
					className="collapse navbar-collapse justify-content-center"
					id="navbarNavDropdown"
				>
					<ul className="navbar-nav">
						<li className="nav-item">
							<a className="nav-link" href="#page-start">
								Start
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="#page-location">
								Location
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="#page-timeline">
								Ablauf
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="#page-informations">
								Informationen
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="#page-registration">
								Anmeldung
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="#page-contact">
								Kontakt
							</a>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	)
}
