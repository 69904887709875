interface IProps {
	status: number | null
	setStatus: (input: number | null) => void
}

type Color = {
	dark: string
	normal: string
}

type Colors = {
	green: Color
	red: Color
}

const colors: Colors = {
	green: {
		dark: '#0f5132',
		normal: '#d1e7dd',
	},
	red: {
		dark: '#842029',
		normal: '#f8d7da',
	},
}

export default function Modal(props: IProps) {
	function handleOverlayClose() {
		props.setStatus(null)
	}

	function renderOverlay<T extends keyof Colors>(color: T, message: string) {
		return (
			<>
				<div
					className={[
						'd-flex',
						'align-items-center',
						'justify-content-center',
						'standing-data-list-overlay',
					].join(' ')}
					onClick={handleOverlayClose}
				>
					<div id="background">
						<div id="menuebar">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								style={{ cursor: 'pointer' }}
								width="20"
								height="20"
								fill="grey"
								className="bi bi-x-circle"
								viewBox="0 0 16 16"
							>
								<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
								<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
							</svg>
						</div>
							<hr style={{marginBottom: '0px', marginTop: '0px'}} />
						<div
							className="container"
							id="main-window"
							style={{
							}}
						>
							{message}
						</div>
					</div>
				</div>
			</>
		)
	}

	if (props.status === 429) {
		return renderOverlay(
			'red',
			'Es wurden zuviele Anmeldungen gemacht. Kontaktiere bitte Nathanael.'
		)
	}
	if (props.status === 200) {
		return renderOverlay('green', 'Deine Anmeldung ist angekommen, vielen lieben Dank.')
	}
	return <></>
}
