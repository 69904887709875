import styles from '../styles/All.module.css'

export default function Contact() {
	const renderCard = (
		name: string,
		phoneHref: string,
		phoneString: string,
		img: string
	): JSX.Element => {
		return (
			<div className="card-1">
				<img
					style={{
						objectFit: 'cover',
						filter: 'grayscale(100%)',

						borderRadius: '50%',
						height: '200px',
						width: '200px',
					}}
					src={img}
					alt={name}
				/>
				<p className={'mt-3 ' + styles['color-white']}>
					{name}
					<br />
					<a href={'tel:' + phoneHref}>{phoneString}</a>
				</p>
				<p></p>
			</div>
		)
	}

	return (
		<div className="component component-contact">
			<h1>Kontakt</h1>

			<p className={'mb-5 ' + styles['color-white']}>
				Ihr habt Fragen oder wollt etwas zum Fest beitragen?
				<br />
				Dann meldet euch ungeniert bei unseren Helfern …
			</p>

			<div
				className="d-flex flex-row flex-wrap justify-content-center"
				style={{ gap: '2em' }}
			>
				{renderCard(
					'Kevin Boutellier',
					'+41797192832',
					'+41 79 719 28 32',
					'/img/portraits/kevin_boutellier.png'
				)}
				{renderCard(
					'Sabrina Brüesch',
					'+41791984153',
					'+41 79 198 41 53',
					'/img/portraits/sabrina_bruesch.png'
				)}
				{renderCard(
					'Judith Erdin',
					'+41796017864',
					'+41 79 601 78 64',

					'/img/portraits/judith_erdin.jpg'
				)}
			</div>
		</div>
	)
}
