import { GuestDb } from '../interfaces/GuestDb'
import { GuestApi } from '../interfaces/GuestApi'

export default function getNamesFromGuests(guests: GuestDb[] | GuestApi[]): string {
	if (guests[0]) {
		const output: string[] = []
		for (const i of guests) {
			output.push(i.Name)
		}
		return output.join(' ' + guests[0].CoupleSign + ' ')
	}
	return ''
}
