import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Routes, Route, Navigate } from 'react-router-dom'

import './styles/globals.scss'
import Invitation from './pages/Invitation'
import NotFound from './components/NotFound'
import SaveTheDate from './pages/SaveTheDate'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
	<BrowserRouter>
		<Routes>
			<Route path="/" element={<Navigate replace to="/index" />} />
			<Route path="/index" element={<SaveTheDate />} />
			<Route path="/invitation" element={<Invitation />} />
			<Route path="/*" element={<NotFound />} />
		</Routes>
	</BrowserRouter>
)

reportWebVitals()
