import styles from '../styles/Index.module.css'
import { useEffect } from 'react'
import { useState } from 'react'

export default function SaveTheDate() {
	const [render, setRender] = useState<boolean>(false)

	useEffect(() => {
		setRender(true)
	}, [])

	return (
		<div>
			<div className={styles.body}>
				<div className="text-center">
					{render && <img width={300} height={428} src="/img/logo.svg" alt="C&N" />}
					<div className={styles.blue}>
						<div className={styles['save-the-date']}>
							SAVE <span className={styles.white}>THE</span> DATE
						</div>
						<hr className={styles.hr} />
						<div className={styles.date}>24 | 06 | 23</div>
						<hr className={styles.hr} />
						<div className={styles.names}>CARMEN & NATHANAEL</div>
						<div className={styles['extended-infos']}>
							Schloss Freudenfels Eschenz TG
							<br />
							Weitere Infos folgen …
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
