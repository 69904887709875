import styles from '../styles/All.module.css'

export default function Footer() {
	return (
		<div className="component component-footer">
			<a href="#page-start">
				<div className={styles['color-grey']}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="40"
						height="40"
						fill="currentColor"
						className="bi bi-chevron-compact-up"
						viewBox="0 0 16 16"
					>
						<path
							fillRule="evenodd"
							d="M7.776 5.553a.5.5 0 0 1 .448 0l6 3a.5.5 0 1 1-.448.894L8 6.56 2.224 9.447a.5.5 0 1 1-.448-.894l6-3z"
						/>
					</svg>
				</div>
			</a>
			<div className={styles['color-gold']}>Carmen & Nathanael 2023</div>
		</div>
	)
}
