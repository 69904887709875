import { GuestApi } from '../../interfaces/GuestApi'
import { getUrl } from '../../constants/URLs'

export type Response = {
	guest: GuestApi[]
	status: number
}

export async function apiCall(token: string, registration: any): Promise<Response> {
	const response = await fetch(getUrl() + '/putRegistration/' + token, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(registration),
	})
	const guest: GuestApi[] = await response.json()
	return {
		guest: guest,
		status: response.status,
	}
}
