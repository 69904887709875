import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useState } from 'react'

import * as apiGetGuests from '../functions/api/getGuests'
import * as apiGetRegistration from '../functions/api/getRegistration'
import * as apiPutRegistration from '../functions/api/putRegistration'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import Informations from '../components/Informations'
import Loading from '../components/Loading'
import Location from '../components/Location'
import Navigation from '../components/Navigation'
import NotFound from '../components/NotFound'
import Registration from '../components/Registration'
import Start from '../components/Start'
import Timeline from '../components/Timeline'
import { GuestApi } from '../interfaces/GuestApi'
import { RegistrationApi } from '../interfaces/RegistrationApi'
import { defaultRegistrationApi } from '../interfaces/RegistrationApi'

export default function Token() {
	const [searchParams] = useSearchParams()

	function getToken(): string {
		const token: string | null = searchParams.get('t')
		if (token) {
			return token
		}
		return ''
	}

	const [guests, setGuests] = useState<GuestApi[]>([])
	const [registration, setRegistration] = useState<RegistrationApi>(defaultRegistrationApi())

	const [alreadyRegistered, setAlreadyRegistered] = useState<boolean>(false)
	const [allGood, setAllGood] = useState<boolean>(false)
	const [status, setStatus] = useState<number | null>(null)
	const [loading, setLoading] = useState<boolean>(true)

	useEffect(() => {
		initialize()
	}, [])

	async function initialize(): Promise<void> {
		setLoading(true)
		await getGuests()
		await getRegistration()
		setLoading(false)
	}

	async function getGuests(): Promise<void> {
		if (getToken()) {
			const guests: GuestApi[] = await apiGetGuests.apiCall(getToken())
			setGuests(guests)
		}
	}

	async function getRegistration(): Promise<void> {
		if (getToken()) {
			const registration: RegistrationApi = await apiGetRegistration.apiCall(getToken())
			if (registration) {
				setRegistration(registration)
				setAlreadyRegistered(true)
			}
		}
	}

	async function putRegistration(): Promise<void> {
		if (getToken()) {
			try {
				const response: apiPutRegistration.Response = await apiPutRegistration.apiCall(
					getToken(),
					registration ?? defaultRegistrationApi()
				)
				setGuests(response.guest)
				setStatus(response.status)
			} catch (e) {
				setStatus(400)
				console.error(e)
			}
		}
	}

	if (loading) {
		return <Loading />
	}
	if (guests.length === 0) {
		return <NotFound />
	}
	if (guests) {
		return (
			<>
				<Navigation />
				<div id="page-start">
					<Start />
				</div>
				<div id="page-location" className="navigation">
					<Location />
				</div>
				<div id="page-timeline" className="navigation">
					<Timeline />
				</div>
				<div id="page-informations" className="navigation">
					<Informations />
				</div>
				<div id="page-registration" className="navigation">
					<Registration
						registration={registration}
						setRegistration={setRegistration}
						alreadyRegistered={alreadyRegistered}
						guests={guests}
						putRegistration={putRegistration}
						allGood={allGood}
						setAllGood={setAllGood}
						setStatus={setStatus}
						status={status}
					/>
				</div>
				<div id="page-contact" className="navigation">
					<Contact />
				</div>
				<Footer />
			</>
		)
	} else {
		return (
			<>
				<h1>Loading</h1>
			</>
		)
	}
}
