import Modal from './Modal'
import getNamesFromGuests from '../functions/getNamesFromGuests'
import { GuestApi } from '../interfaces/GuestApi'
import { RegistrationApi } from '../interfaces/RegistrationApi'

interface Props {
	guests: GuestApi[]
	registration: RegistrationApi
	setRegistration: (input: RegistrationApi) => void
	alreadyRegistered: boolean
	putRegistration: () => Promise<void>
	allGood: boolean
	setAllGood: (input: boolean) => void
	status: number | null
	setStatus: (input: number | null) => void
}

export default function Registration(props: Props) {
	function handleButtonClick(): void {
		props.putRegistration()
	}

	function getIntroductionText(): string {
		if (props.guests.length > 1) {
			return 'Wir freuen uns auf eure Anmeldung bis am 10.04.2023'
		}
		return 'Wir freuen uns auf deine Anmeldung bis am 10.04.2023'
	}

	function changeAmounts(e: any): void {
		const key: 'AmountPersons' | 'AmountVegetarians' = e.target.id
		const value: number = Number(e.target.value)

		const registration = { ...props.registration }
		registration[key] = value
		props.setRegistration(registration)
	}

	function changeCheckbox(e: any): void {
		const key: 'WithPublicTransport' | 'WithCar' | 'WithRideshare' = e.target.id
		const value: boolean = e.target.checked

		const registration = { ...props.registration }
		registration[key] = value
		props.setRegistration(registration)
	}

	return (
		<div className="component component-registration">
			<div className="container">
				{props.alreadyRegistered && (
					<div
						style={{
							backgroundColor: '#b17f4c',
							color: 'white',
							padding: '5px',
							marginBottom: '20px',
						}}
					>
						Eine Anmeldung ist bereits bei uns eingetroffen.
					</div>
				)}
				<h1>Anmeldung</h1>

				<p>{getIntroductionText()}</p>
				<h2 style={{ marginBottom: '30px' }}>{getNamesFromGuests(props.guests)}</h2>
				<div className="form-floating mb-3">
					<input
						onChange={changeAmounts}
						type="number"
						className="form-control"
						id="AmountPersons"
						placeholder="1"
						min="0"
						defaultValue={props.registration?.AmountPersons ?? undefined}
					/>
					<label htmlFor="floatingInput">Anzahl Personen</label>
				</div>
				<div className="form-floating mb-3">
					<input
						onChange={changeAmounts}
						type="number"
						className="form-control"
						id="AmountVegetarians"
						placeholder="1"
						min="0"
						defaultValue={props.registration?.AmountVegetarians ?? undefined}
					/>
					<label htmlFor="floatingInput">Anzahl Vegetarier</label>
				</div>

				<div style={{ textAlign: 'left' }}>
					<strong>Wir kommen mit ...</strong>
					<div className="form-check" style={{ marginBottom: '5px', marginTop: '5px' }}>
						<input
							onChange={changeCheckbox}
							className="form-check-input"
							type="checkbox"
							id="WithCar"
							checked={props.registration?.WithCar}
						/>
						<label className="form-check-label" htmlFor="WithCar">
							... einem Auto
						</label>
					</div>
					<div className="form-check" style={{ marginBottom: '5px' }}>
						<input
							onChange={changeCheckbox}
							className="form-check-input"
							type="checkbox"
							id="WithPublicTransport"
							checked={props.registration?.WithPublicTransport}
						/>
						<label className="form-check-label" htmlFor="WithPublicTransport">
							... dem ÖV
						</label>
					</div>
					<div className="form-check">
						<input
							onChange={changeCheckbox}
							className="form-check-input"
							type="checkbox"
							id="WithRideshare"
							checked={props.registration?.WithRideshare}
						/>
						<label className="form-check-label" htmlFor="WithRideshare">
							... einer Mitfahrgelegenheit
						</label>
					</div>
				</div>
				<br />

				<button
					style={{ width: '100%' }}
					type="button"
					className="btn btn-lg btn-outline-primary"
					onClick={handleButtonClick}
				>
					Speichern
				</button>
				<Modal status={props.status} setStatus={props.setStatus} />
			</div>
		</div>
	)
}
